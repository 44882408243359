import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import defaultUser from "../../../assets/images/defaultUserProfile.png";
import defaultBg from "../../../assets/images/bg.webp";
import { IMAGE_PATH } from "../../../constant/constant";
import lectureServices from "../../../services/lectureServices";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../../utils/store/uiSlice";

const ClassCard = ({ classes = [] }) => {
  const { user } = useSelector((store) => store.user);

  const [checkedState, setCheckedState] = useState({});
  const dispatch = useDispatch();

  // Initialize the checkedState only once when the component mounts
  useEffect(() => {
    if (classes.length > 0) {
      const initialCheckedState = {};
      classes.forEach((singleClass) => {
        initialCheckedState[singleClass._id] = singleClass.isBlocked;
      });
      setCheckedState(initialCheckedState);
    }
  }, [classes]); // The state is initialized when 'classes' is received

  // Handle checkbox toggle
  const handleLock = async (classId, userId) => {
    try {
      const currentState = checkedState[classId];
      const newLockedState = !currentState; // Toggle the locked state

      // Call API to update lock state
      const response = await lectureServices.getUserLocked(
        classId,
        userId,
        newLockedState
      );
      if (response?.success) {
        // Directly update the checkedState without using ...prevState
        setCheckedState((prevState) => {
          const updatedState = { ...prevState };
          updatedState[classId] = newLockedState; // Update only the specific class

          return updatedState;
        });

        // Dispatch success notification
        dispatch(
          setNotification({
            success: true,
            message: `Client has been ${
              newLockedState ? "locked" : "unlocked"
            }.`,
          })
        );
      } else {
        console.error("API failed to return success.");
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  return (
    <>
      {classes.map((singleClass) => (
        <div key={singleClass._id} className="h-72">
          <div
            className={`overflow-hidden shadow max-w-[500px] h-full relative ${
              checkedState[singleClass._id]
                ? "relative hover:before:absolute hover:before:rotate-[-45deg] hover:before:px-[2rem] hover:before:py-[3px] hover:before:font-bold hover:before:text-white hover:before:left-[-2rem] hover:before:top-[1rem] hover:before:bg-red-500 hover:before:z-[2] hover:before:content-['LOCKED']"
                : ""
            }`}
          >
            <div className="flex h-3/5">
              <img
                draggable="false"
                src={
                  (singleClass.image &&
                    `${IMAGE_PATH}/classroom/${singleClass.image}`) ||
                  defaultBg
                }
                alt="background"
                className="w-full"
              />
            </div>

            {!checkedState[singleClass._id] && (
              <Link
                to={`/company/${singleClass._id}/dashboard`}
                className="h-72"
              >
                <div className="absolute inset-0" />
              </Link>
            )}

            <div className="px-5 py-1 relative">
              <img
                draggable="false"
                src={
                  (singleClass?.userId?.avatar &&
                    `${IMAGE_PATH}/profiles/${singleClass?.userId?.avatar}`) ||
                  defaultUser
                }
                alt="avatar"
                className="absolute right-8 top-[-3rem] rounded w-[80px] h-[80px] border-2 border-white bg-white"
              />

              <p className="font-bold text-textColor relative">
                {singleClass?.name}
              </p>
              <p className="mt-2 text-sm text-textColor relative">
                {singleClass?.description}
              </p>

              <div>
                {/* Case 1: User is Consultancy and Class Type is also Consultancy */}
                {singleClass?.userId?.type === "Consultancy" ? null : ( // Do not display toggle if logged-in user is 'Consultancy' and class type is 'Consultancy'
                  /* Display toggle for non-Consultancy users viewing Consultancy classes */
                  <label className="inline-flex items-center me-5 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={checkedState[singleClass._id] || false}
                      className="w-14 sr-only peer"
                      onChange={() =>
                        handleLock(singleClass._id, singleClass.userId?._id)
                      }
                    />
                    <div className="relative w-14 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      {checkedState[singleClass._id]
                        ? "Client has been locked"
                        : null}
                    </span>
                  </label>
                )}
              </div>

              <div>
                {/* Case 2: User is Company and Class Type is Company */}
                {singleClass?.userId?.type !== "Company" ? null : null}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ClassCard;
