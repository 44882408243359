import { useState, useEffect } from "react";
import background from "../../assets/images/reminder.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNotification } from "../../utils/store/uiSlice";
import axios from "axios";
import { decodeUser } from "../../utils/storageHelper";
import OneTimeSubscriptionPlan from "../../components/classroom/common/OneTimeSubscriptionPlan";

const SubscriptionDenied = () => {
  const user = decodeUser();
  const [popIsVisible, setPopIsVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const popHandle = () => {
    if (user) {
      setPopIsVisible(true);
    } else {
      navigate("/auth/login", { state: { from: location.pathname } });
    }
  };

  return (
    <>
      <div className="relative flex justify-center items-center h-screen flex-col p-4 text-center text-gray-400 overflow-hidden">
        {/* Popup for One-Time Subscription Plan */}
        {popIsVisible && (
          <OneTimeSubscriptionPlan
            visible={popIsVisible}
            setPopIsVisible={setPopIsVisible}
            setVisible={setPopIsVisible}
          />
        )}

        {/* Main Banner Section */}
        <div
          className={`relative w-4/4 max-w-[500px] md:max-w-[530px] mb-4 ${
            popIsVisible ? "hidden" : ""
          }`}
        >
          {/* Banner Image */}
          <img
            draggable="false"
            src={background}
            style={{ width: "700px", height: "340px", marginTop: "-190px" }}
            alt="Subscription Denied"
            className="object-cover w-full h-auto"
          />

          {/* Text Overlay */}
          <div
            style={{ marginTop: "-150px", marginLeft: "40px" }}
            className="absolute inset-0 flex flex-col items-center justify-center p-2"
          >
            <h2 className="text-lg font-semibold text-black mb-2">
              We're sorry, but you cannot access this feature without a
              subscription.
            </h2>
            <p className="text-sm text-black mb-2">
              If you would like to register your clients or companies, please
              make a one-time payment for each company you wish to create.
            </p>
            <p className="text-sm text-black">
              For assistance, please contact our sales team.
            </p>
          </div>
        </div>

        {/* Take Subscription Button (Only Visible in Main View) */}
        {!popIsVisible && (
          <button
            className="relative px-4 py-2 md:py-3 flex justify-between items-center rounded bg-gradient-to-r from-primary to-black text-white text-lg md:max-w-[250px] transition-all duration-200 cursor-pointer hover:shadow-lg z-10"
            onClick={popHandle}
          >
            <span className="text-lg md:text-xl font-bold">
              Take Subscription
            </span>
          </button>
        )}
      </div>
    </>
  );
};

export default SubscriptionDenied;
