import axios from "axios";
import { getToken } from "../utils/storageHelper";
import { BASE_URL } from "../constant/constant";

const setAxiosToken = () => {
  const token = getToken();
  axios.defaults.headers.common["Authorization"] = token;
};

const screeningServices = {
  makeRequest: async (url, method, data) => {
    try {
      setAxiosToken();
      const response = await axios({
        url,
        method,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.log(
        "%c server " + error.message,
        "color: green; font-weight:bold;"
      );
    }
  },
  updateScreeningCvs: async (scancvId, announcementId, values) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    return axios
      .patch(
        BASE_URL +
          `/screeningcv/${scancvId}/updateScreeningcv/${announcementId}`,
        {
          values,
        }
      )
      .then((res) => {
        return res.data;
      });
  },
  // updateScreeningCvsData: async (scancvId, announcementId, values) => {
  //   return screeningServices.makeRequest(
  //     BASE_URL + `/screeningcv/${scancvId}/updateScreeningcv/${announcementId}`,
  //     "POST",
  //     values
  //   );
  // },
};

export default screeningServices;
