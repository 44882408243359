import { useState, useEffect } from 'react';
import Header from '../components/classroom/header/Header';
import Section from '../components/layouts/Section';
import Footer from '../components/layouts/Footer';
import image from '../assets/images/photo-young-lovely-girl-manager-600nw-2116114139.webp';
import image2 from '../assets/images/high-school-principal-using-digital-260nw-2339228201.webp';
import image3 from '../assets/images/hand-drawn-student-discount-sale-banner_23-2150639191.avif';
import { BASE_URL, BASIC_FEATURES, PREMIUM_FEATURES } from '../constant/constant';
import contactImage from '../assets/images/flat-customer-service-week-horizontal-banner-template_23-2149645764.avif';

import { decodeUser } from "../utils/storageHelper";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from '../components/common/Notification';
import SubscriptionPlan from '../components/classroom/common/SubscriptionPlan';
import Subscription from './Subscription';
import './homeAnimation';
import { useDispatch } from 'react-redux';
import { setNotification } from '../utils/store/uiSlice';
import axios from 'axios';

const Privacy = () => {
  const user = decodeUser();

  const [plan, setPlan] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch()

  const [popIsVisible, setPopIsVisible] = useState(false);

  useEffect(()=>{
    const showPopup = location.state?.showPopup;   
    if(showPopup === true){       
        setPopIsVisible(true);    
      }
  },[])
      

  const popHandle = () => {
    if(user){
      setPopIsVisible(true);      
    } else {
      navigate('/auth/login', { state: { from: location.pathname } });
    }
    
  };

  const clickHandle = (plan) => {
    setPlan(plan);
  };

  const handleContact = async (e) => {
    e.preventDefault();
    let error;
    if (!name.trim()) { error = 'name is required'}
    else if (!email.trim()) { error = 'email is required'}
    else if (!message.trim()) { error = 'message is required'}
    if (!error) {
      try {
        const {data} = await axios.post(`${BASE_URL}/message/send-mail`, {
          name: name.trim(), sender: email.trim(), message: message.trim()
        })
        if (data?.success?.isSended) {
          setName('')
          setEmail('')
          setMessage('')
        }
        dispatch(setNotification({ 
          success: data?.success?.isSended,
          message: data?.success?.isSended ? 'mail is sended' : 'failed to send mail'
        }))
      } catch (error) {
        
      }
    }
    else {
      dispatch(setNotification({ success: false, message: error }))
    }
  };

  return (
    <>
      <div className="body">
        <Notification />
        <SubscriptionPlan visible={popIsVisible} setPopIsVisible={setPopIsVisible} setVisible={setPopIsVisible} />
        <div className="main min-h-screen 2xl:min-h-[100px]">
          <Header page="home" />
          </div>       
        <Section> 
          <div>
                <p>
                <h4>Privacy Policy</h4>
                <hr className="mt-0 mb-4" />  
                <h6>Effective as of October 7, 2024 </h6>
                </p>            
                <p>
                    At OSSTechHire, we are committed to safeguarding the privacy and personal information of all users, whether you are an IT consultancy or an IT company using our platform. This Privacy Policy outlines the information we collect, how we use it, and how we protect it. By using OSSTechHire.com, you agree to the practices described in this policy.
                </p>                
                <p>
                <h4>1. Data Collection:</h4> 
                    <p>We collect personal data during registration, including names, email addresses, contact numbers, and company information. We also collect billing information for subscriptions and services.</p>
                </p>
                <p>
                <h4>2. Use of Collected Information:</h4> 
                    <p>The information collected is used to:</p>
                    <ul>
                      <li>Provide and enhance our services, including IT consultancy and company registrations.</li>
                      <li>Facilitate subscription payments, including the applicable GST as per Indian Government policy.</li>
                      <li>Enable communication and provide support via email services.</li>
                      <li>Offer premium services like ChatGPT and GeminiAPI for data analysis and filtering.</li>
                    </ul>
                </p>

                <h4>3. Third-Party Services:</h4>               
                <p>
                We use third-party services such as payment gateways, AI-powered tools (e.g., ChatGPT, GeminiAPI), and email providers. These services may collect data necessary for their functioning, and we are not responsible for their privacy policies.
                </p>

                <h4>4. Data Security:</h4>
                  <p>We implement industry-standard security measures to protect your data. However, no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee absolute security, but we strive to keep your information safe.</p>
               
               <h4>5. Data Retention:</h4>
               <p>
               We retain user information for as long as necessary to fulfill the services requested, comply with legal obligations, or resolve disputes. Data can be requested to be deleted by contacting our support team.
               </p>

               <h4>6. Cookies:</h4>
               <p>
               Our platform may use cookies to improve user experience. These small files are stored on your device to track preferences and session information. You can disable cookies in your browser settings, though this may affect your experience.
               </p>

              <h4>7. User Rights:</h4>
              <p>
              You have the right to access, correct, or delete the personal information we hold about you. You can also opt-out of marketing communications or request details on how your data is processed.
              </p>

              <h4>8. Consent:</h4>
              <p>
              By using OSSTechHire.com, you consent to our privacy practices as described in this policy. If you disagree, please do not use our platform.
              </p>

              <h4>9. Updates to Privacy Policy:</h4>
              <p>
              We may update this policy from time to time. Users will be notified of any changes via email or through updates on the platform.
              </p>

              <h4>10. Contact Information:</h4>
              <p>
              For any concerns or inquiries regarding our Privacy Policy, please contact us at support@osstechhire.com.
              </p>

              <p>
              Thank you for choosing OSSTechHire as your managing serveral services which is belongs to as a consultancy or CRM platform. Happy learning!
              </p>                          
          </div>
        </Section>

        <Section>
        <Footer />
        </Section>
      </div>
    </>
  );
};

export default Privacy;
