import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Section from "../../layouts/Section";
import ClassCards from "./ClassCards";
import classServices from "../../../services/classServices";
import { useDispatch, useSelector } from "react-redux";
import {
  storeClasses,
  setCurrentClass,
} from "../../../utils/store/classesSlice";
import { setNotification } from "../../../utils/store/uiSlice";

const AllClasses = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const storeClass = useSelector((store) => store.classes);

  useEffect(() => {
    classServices
      .getAllClasses()
      .then((res) => {
        if (res?.classes) {
          dispatch(storeClasses(res?.classes));
          classServices.getClass(res?.classes[0]?._id).then((res) => {
            setIsLoaded(true);
            dispatch(setCurrentClass(res?.success?.class));
          });
        }
      })
      .catch((err) => {
        dispatch(setNotification({ success: false, message: err.message }));
      });
  }, []);

  useEffect(() => {
    if (isLoaded === true) {
      //console.log('classData',storeClass?.classes[0]?._id);
      navigate("/company/" + storeClass?.classes[0]?._id + "/dashboard");
    }
  }, []);

  return (
    <>
      <Section className="mb-14">
        {storeClass?.classes?.length === 0 && (
          <div className="h-[50vh] grid place-items-center text-center md:text-5xl md:font-bold text-gray-200">
            Didn't got any job?
            <br /> or you want to got new job <br /> upload your resume or cv
            here <br /> you can make digital your life with us so please share
            every where it's free for student's & candidates{" "}
          </div>
        )}

        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7">
          {!isLoaded ? (
            Array(3)
              .fill()
              .map((e, index) => (
                <div
                  className="shim overflow-hidden shadow max-w-[500px] h-[250px] relative"
                  key={index}
                >
                  <div className="flex h-3/5 bg-gray-200"></div>
                  <div className="px-5 py-1 relative before:absolute before:content-[''] before:w-[150%] before:h-[5rem] before:-top-4 before:-left-4 before:rotate-3 md:before:rotate-6 before:bg-white ">
                    <div className="absolute right-8 top-[-3rem] w-[80px] h-[80px] bg-gray-300" />
                    <p className="font-bold text-textColor"></p>
                    <p className="mt-2 text-sm text-textColor"></p>
                  </div>
                </div>
              ))
          ) : (
            <ClassCards classes={storeClass?.classes} />
          )}
        </div>
      </Section>
    </>
  );
};

export default AllClasses;
