import { useState, useEffect } from "react";
import Header from "../components/classroom/header/Header";
import Section from "../components/layouts/Section";
import Footer from "../components/layouts/Footer";
import image from "../assets/images/photo-young-lovely-girl-manager-600nw-2116114139.webp";
import image2 from "../assets/images/high-school-principal-using-digital-260nw-2339228201.webp";
import image3 from "../assets/images/132556.jpg";
import {
  BASE_URL,
  BASIC_FEATURES,
  PREMIUM_FEATURES,
} from "../constant/constant";
import contactImage from "../assets/images/flat-customer-service-week-horizontal-banner-template_23-2149645764.avif";

import { decodeUser } from "../utils/storageHelper";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../components/common/Notification";
import SubscriptionPlan from "../components/classroom/common/SubscriptionPlan";
import Subscription from "./Subscription";
import "./homeAnimation";
import { useDispatch } from "react-redux";
import { setNotification } from "../utils/store/uiSlice";
import axios from "axios";

const Aboutus = () => {
  const user = decodeUser();

  const [plan, setPlan] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [popIsVisible, setPopIsVisible] = useState(false);

  useEffect(() => {
    const showPopup = location.state?.showPopup;
    if (showPopup === true) {
      setPopIsVisible(true);
    }
  }, []);

  const popHandle = () => {
    if (user) {
      setPopIsVisible(true);
    } else {
      navigate("/auth/login", { state: { from: location.pathname } });
    }
  };

  const clickHandle = (plan) => {
    setPlan(plan);
  };

  const handleContact = async (e) => {
    e.preventDefault();
    let error;
    if (!name.trim()) {
      error = "name is required";
    } else if (!email.trim()) {
      error = "email is required";
    } else if (!message.trim()) {
      error = "message is required";
    }
    if (!error) {
      try {
        const { data } = await axios.post(`${BASE_URL}/message/send-mail`, {
          name: name.trim(),
          sender: email.trim(),
          message: message.trim(),
        });
        if (data?.success?.isSended) {
          setName("");
          setEmail("");
          setMessage("");
        }
        dispatch(
          setNotification({
            success: data?.success?.isSended,
            message: data?.success?.isSended
              ? "mail is sended"
              : "failed to send mail",
          })
        );
      } catch (error) {}
    } else {
      dispatch(setNotification({ success: false, message: error }));
    }
  };

  return (
    <>
      <div className="body">
        <Notification />
        <SubscriptionPlan
          visible={popIsVisible}
          setPopIsVisible={setPopIsVisible}
          setVisible={setPopIsVisible}
        />
        <div className="main min-h-screen 2xl:min-h-[100px]">
          <Header page="home" />
        </div>
        <Section>
          <div>
            <p>
              <h4>OSSTechhire Aboutus</h4>
              <hr className="mt-0 mb-4" />
              <h6>Effective as of October 7, 2024 </h6>
            </p>
            <p>
              <h6>
                Welcome to OSSTechhire - Empowering Your Business via our
                services
              </h6>
            </p>{" "}
            <br />
            <p>
              Hello Everyone, <br /> At Unlock Your Full Potential Anywhere,
              Anytime: Embark on a Journey of Consultancy & IT Companies
              Excellence with Our Innovative Solutions with us.
            </p>{" "}
            <br />
            <p>
              About OSSTechhire: Welcome to OSSTechhire, a revolutionary
              platform designed to streamline IT consultancy and hiring
              processes for both IT consultancy organizations and IT companies.
              With a unique blend of tools and features, OSSTechhire offers an
              innovative solution for managing talent acquisition, interviews,
              and consultancy needs in a highly efficient and customizable way.
            </p>{" "}
            <br />
            <p>
              <h4>Tailored for IT Consultancy Organizations and Firms</h4>
              <p>
                For IT consultancy firms, OSSTechhire offers a platform where
                you can seamlessly schedule interviews for your clients or
                channel partners. With a focus on simplifying the hiring
                process, you can manage recruitment workflows efficiently,
                ensuring the right talent gets connected to the right companies.
                Whether it's organizing interviews, tracking progress, or
                streamlining communications, OSSTechhire empowers consultancies
                to optimize their operations and deliver the best outcomes for
                their clients.
              </p>
            </p>{" "}
            <br />
            <p>
              <h4>Built for IT Companies</h4>
              <p>
                For IT companies, OSSTechhire serves as a one-stop platform for
                managing your recruitment process end-to-end. Whether you need
                to hire directly or work with external consultancies,
                OSSTechhire provides tools to facilitate interviews, track
                candidates, and integrate with popular services like Google Meet
                and Microsoft Teams for seamless online interactions. This
                all-in-one platform allows you to focus on building the best
                teams while automating much of the heavy lifting.
              </p>
            </p>{" "}
            <br />
            <p>
              <h6>Key Features of OSSTechhire</h6> <br />
              <p>
                <h4>1.Run Your Own Subdomain</h4>
                <p>
                  OSSTechhire offers a flexible platform that allows IT
                  consultancies and companies to run the service on their own
                  custom subdomain, with a separate, dedicated database for each
                  subdomain. This ensures data privacy, security, and
                  customization, allowing you to tailor the platform to meet
                  your specific needs.
                </p>
              </p>{" "}
              <br />
              <p>
                <h4>2. No Domain Renewal Needed</h4>
                <p>
                  With OSSTechhire, you don’t have to worry about domain renewal
                  hassles. Once your subdomain is set up, we take care of
                  everything, allowing you to focus on your core operations
                  while we handle the technical details.
                </p>
              </p>{" "}
              <br />
              <p>
                <h4>3. ChatGPT AI Integration—Free of Cost</h4>
                <p>
                  One of the standout features of OSSTechhire is its free
                  integration with ChatGPT AI services. You can harness the
                  power of AI-driven automation to assist with tasks like resume
                  screening, answering queries, or even conducting preliminary
                  interviews—all without paying any extra fees for AI usage.
                </p>
              </p>{" "}
              <br />
              <p>
                <h4>4. Access to Third-Party Services</h4>
                <p>
                  OSSTechhire is built to integrate with other essential
                  platforms like Google Meet and Microsoft Teams. You can access
                  these tools directly through our platform, making it easy to
                  schedule and conduct interviews without switching between
                  applications.
                </p>
              </p>{" "}
              <br />
              <p>
                <h4>
                  5. Interview Scheduling for Channel Partners and Companies
                </h4>
                <p>
                  IT consultancies can use OSSTechhire to schedule interviews
                  not only for themselves but also on behalf of partner
                  companies or channel partners. This makes it incredibly easy
                  to manage multiple clients, improving communication and
                  collaboration between consultancies and their business
                  partners.
                </p>
              </p>{" "}
              <br />
              <p>
                At OSSTechhire, our mission is to create an ecosystem where IT
                consultancies and IT companies can operate efficiently, leverage
                advanced tools, and drive success in talent acquisition without
                the typical overhead costs. Our platform’s flexibility,
                customization options, and powerful AI integrations are designed
                to save you time, streamline processes, and help you focus on
                what matters most—building the future of IT with the right
                talent.
              </p>
            </p>{" "}
            <br />
            <p>
              Discover the future of IT hiring with OSSTechhire—where technology
              meets recruitment!
            </p>
          </div>
        </Section>

        <Section>
          <Footer />
        </Section>
      </div>
    </>
  );
};

export default Aboutus;
