import { Suspense, lazy } from "react";
import Shimmer from "../components/common/Shimmer";
import ErrorBoundary from "../pages/ErrorBoundary";
import ViewQuestionAnswers from "../pages/instructor/ViewQuesstionAnswers";
import ViewSubmission from "../pages/instructor/ViewSubmission";
import PrivateRoute from "../components/PrivateRoute";
import DirectCompanyRoute from "../components/DirectCompanyRoute";

const Dashboard = lazy(() => import("../pages/instructor/Dashboard"));
const Attendance = lazy(() => import("../pages/instructor/Attendance"));

const LectureStudentsManagement = lazy(() =>
  import("../components/classroom/Lecture/LectureStudentsManagement")
);

const AssignCandidateCvs = lazy(() =>
  import("../components/classroom/Lecture/AssignCandidateCvs")
);

const Feedback = lazy(() => import("../components/classroom/Lecture/Feedback"));

const CreateClass = lazy(() => import("../components/common/CreateClass"));

const UploadCv = lazy(() => import("../components/common/UploadCv"));

const Profile = lazy(() => import("../components/common/Profile"));

const Orders = lazy(() => import("../components/common/Orders"));

const EditProfile = lazy(() => import("../components/common/EditProfile"));

const LayoutWithSidebar = lazy(() =>
  import("../components/layouts/LayoutWithSidebar")
);
const AnnouncementManagement = lazy(() =>
  import("../pages/instructor/AnnouncementManagement")
);
const AssignmentManagement = lazy(() =>
  import("../pages/instructor/AssignmentManagement")
);
const EditAssignment = lazy(() => import("../pages/instructor/EditAssignment"));

const Profilecheck = lazy(() => import("../pages/instructor/Profilecheck"));
const EditProfilecheck = lazy(() =>
  import("../pages/instructor/EditProfilecheck")
);

const CreateQuestions = lazy(() =>
  import("../pages/instructor/CreateQuestions")
);
const Submissions = lazy(() => import("../pages/instructor/Submissions"));
const QuizManagement = lazy(() => import("../pages/instructor/QuizManagement"));
const Settings = lazy(() => import("../components/classroom/Lecture/Settings"));
const CreateAnnouncement = lazy(() =>
  import("../components/instructor/CreateAnnouncement")
);
const Openings = lazy(() => import("../components/instructor/Opening"));

const DiscussionPanel = lazy(() =>
  import("../components/classroom/common/DiscussionPanel")
);

const lectureRoute = {
  path: "dashboard",
  element: (
    <Suspense fallback={<Shimmer />}>
      <ErrorBoundary>
        <LayoutWithSidebar />
      </ErrorBoundary>
    </Suspense>
  ),
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
    {
      path: ":profilecheckId",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Dashboard />
        </Suspense>
      ),
    },
    {
      path: ":profilecheckId/company/:assignmentId/openings",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Openings />
        </Suspense>
      ),
    },
    {
      path: "company/:assignmentId/openings",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Openings />
        </Suspense>
      ),
    },
    {
      path: "create",
      element: (
        <PrivateRoute isProtected={true}>
          <Suspense fallback={<Shimmer />}>
            <CreateClass />
          </Suspense>
        </PrivateRoute>
      ),
    },
    {
      path: "profile",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Profile />
        </Suspense>
      ),
    },
    {
      path: "profile/edit",
      element: (
        <Suspense fallback={<Shimmer />}>
          <EditProfile />
        </Suspense>
      ),
    },
    {
      path: "orders",
      element: (
        // <PrivateRoute isProtected={true}>
        <DirectCompanyRoute isProtected="Company">
          <Suspense fallback={<Shimmer />}>
            <Orders />
          </Suspense>
        </DirectCompanyRoute>
        //</PrivateRoute>
      ),
    },
    {
      path: "uploadcv",
      element: (
        // <PrivateRoute isProtected="Consultancy">
        <DirectCompanyRoute isProtected="Company">
          <Suspense fallback={<Shimmer />}>
            <UploadCv />
          </Suspense>
        </DirectCompanyRoute>
        // </PrivateRoute>
      ),
    },
    {
      path: "profilecheck",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Profilecheck />
        </Suspense>
      ),
    },
    {
      path: "profilecheck/:profilecheckId",
      element: (
        <Suspense fallback={<Shimmer />}>
          <EditProfilecheck />
        </Suspense>
      ),
    },
    {
      path: "candidatecvs",
      element: (
        <Suspense fallback={<Shimmer />}>
          <LectureStudentsManagement />
        </Suspense>
      ),
    },
    {
      path: ":assignmentId/assigncandidatecvs",
      element: (
        <Suspense fallback={<Shimmer />}>
          <AssignCandidateCvs />
        </Suspense>
      ),
    },
    {
      path: ":assignmentId/feedback",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Feedback />
        </Suspense>
      ),
    },
    {
      path: "client",
      element: (
        <Suspense fallback={<Shimmer />}>
          <AssignmentManagement />
        </Suspense>
      ),
    },
    {
      path: "client/:assignmentId",
      element: (
        <Suspense fallback={<Shimmer />}>
          <EditAssignment />
        </Suspense>
      ),
    },
    {
      path: "client/:assignmentId/openings",
      element: (
        <Suspense fallback={<Shimmer />}>
          <CreateAnnouncement />
        </Suspense>
      ),
    },
    {
      path: "client/:assignmentId/submissions",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Submissions />
        </Suspense>
      ),
    },
    {
      path: "client/assignCandidate/:candidateId",
      element: (
        <Suspense fallback={<Shimmer />}>
          <DiscussionPanel />
          {/* <AssignmentManagement />  */}
        </Suspense>
      ),
    },
    {
      path: "assignments/:assignmentId/submissions/:submissionId",
      element: (
        <Suspense fallback={<Shimmer />}>
          <ViewSubmission />
        </Suspense>
      ),
    },
    {
      path: "announcements",
      element: (
        <Suspense fallback={<Shimmer />}>
          <AnnouncementManagement />
        </Suspense>
      ),
    },
    {
      path: "attendance",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Attendance />
        </Suspense>
      ),
    },
    {
      path: "quizzes-and-exams",
      element: (
        <Suspense fallback={<Shimmer />}>
          <QuizManagement />
        </Suspense>
      ),
    },
    {
      path: "quizzes-and-exams/create",
      element: (
        <Suspense fallback={<Shimmer />}>
          <CreateQuestions />
        </Suspense>
      ),
    },
    {
      path: "quizzes-and-exams/:questionId",
      element: (
        <Suspense fallback={<Shimmer />}>
          <ViewQuestionAnswers />
        </Suspense>
      ),
    },
    {
      path: "settings",
      element: (
        <Suspense fallback={<Shimmer />}>
          <Settings />
        </Suspense>
      ),
    },
  ],
};

export default lectureRoute;
