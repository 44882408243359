import { useEffect, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authServices from "../../services/authService";
import verifiedImg from "../../assets/images/verified.gif";
import loading from "../../assets/images/loader.gif";
import { useFormik } from "formik";
import Button from "../common/Button";
import { setNotification } from "../../utils/store/uiSlice";

const ScheduleStatus = () => {
  const [verified, setVerified] = useState(false);
  const { scheduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      RejectedBy: "Candidate",
      description: "",
    },
    onSubmit: async (values) => {
      const data = { ...values };
      await authServices.interviewStatus(scheduleId, data).then((res) => {
        if (res.success) {
          dispatch(
            setNotification({
              success: true,
              message: "Interview Status has been changed.....",
            })
          );
          setTimeout(() => {
            window.close();
          }, 7000);
        }
      });
    },
  });

  return (
    <div className="relative w-full h-[100vh] flex items-center justify-center flex-col">
      <Form onSubmit={formik.handleSubmit} className="flex flex-col">
        <label htmlFor="description">
          Please provide the Cancel or Reschedule reason in short details{" "}
          {formik?.errors?.description && (
            <span className="text-red-500"> {formik?.errors?.description}</span>
          )}
        </label>
        <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">
          <textarea
            name="description"
            id="description"
            rows={4}
            cols={4}
            value={formik?.values.description}
            onChange={formik?.handleChange}
            className="w-full outline-none"
          />
        </div>
        <Button
          type="submit"
          className="mt-2 btn overflow-hidden bg-primary hover:bg-indigo-600 px-1 py-1 rounded text-white font-bold text-center shadow-sm shadow-shadow "
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ScheduleStatus;
