import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../utils/storageHelper";
import { useEffect, useState } from "react";
import axios from "axios";
import Shimmer from "./common/Shimmer";
import authServices from "../services/authService";
import { useDispatch } from "react-redux";
import { userLogOut, userLogin } from "../utils/store/userSlice";
import { BASE_URL } from "../constant/constant";
import AccessDenied from "./common/AccessDenied";
import SubscriptionDenied from "./common/SubscriptionDenied";

const PrivateRoute = ({ children, isProtected }) => {
  const [token, setToken] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [hasAccess, setHasAccess] = useState(false);

  // useEffect(() => {
  //    authServices.getMe().then(res=>{
  //     if (res?.success){
  //       dispatch(userLogin(res?.success?.user))
  //     }
  //     if(res?.success?.user?.isBlocked){
  //       dispatch(userLogOut())
  //       setTimeout(()=>{
  //         localStorage.clear();
  //         navigate('/home')},5000)
  //       setIsBlocked(true)
  //     }
  //   })
  //   getLocalStorage('authentication').then((token) => {
  //     if (!token) {
  //       setToken(false);
  //       navigate('/home');
  //     } else {
  //       setToken(true);
  //     }
  //   }).catch((err)=>{
  //     console.log(err);
  //   });
  // }, [dispatch, location, navigate]);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const res = await authServices.getMe();
        if (res?.success) {
          dispatch(userLogin(res.success.user));
          // Check if the user is blocked
          if (res.success.user.isBlocked) {
            dispatch(userLogOut());
            setTimeout(() => {
              localStorage.clear();
              navigate("/home");
            }, 5000);
            setIsBlocked(true);
            return;
          }
          // Check subscription status
          const subscription = res.success.user.subscriber || [];
          const hasValidSubscription = subscription.some(
            (sub) => sub.status === true
          );
          const company = res.success.user.type;
          const subscribeId = res.success.user.subscriber?.[0]?.id;
          setHasAccess({
            valid: hasValidSubscription,
            type: company,
            id: subscribeId,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/home"); // Navigate to home on error
      }
    };

    getLocalStorage("authentication")
      .then((token) => {
        if (!token) {
          setToken(false);
          navigate("/home");
        } else {
          setToken(true);
          checkUser();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, location, navigate]);

  if (!token) {
    return <Shimmer />;
  }
  if (isBlocked) {
    return <AccessDenied />;
  }
  if (isProtected && hasAccess.valid === false && hasAccess.type == "Company") {
    return <AccessDenied />;
  }

  if (isProtected == "Consultancy") {
    return children;
  }

  if (
    isProtected === true &&
    hasAccess.valid === false &&
    !hasAccess.id &&
    hasAccess.type == "Consultancy"
  ) {
    return <SubscriptionDenied />;
  }

  axios.defaults.baseURL = BASE_URL;

  return children;
};

export default PrivateRoute;
