import * as Yup from "yup";

// Regular expressions to match email and phone number patterns
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const phoneRegex = /^\d{3}\d{3}\d{4}$/;

const emailOrPhoneSchema = Yup.string().test(
  "emailOrPhone",
  "Invalid email or phone number",
  function (value) {
    if (!value) {
      return false;
    }

    // Check if the value matches either the email or phone number pattern
    return emailRegex.test(value) || phoneRegex.test(value);
  }
);

// login schema
export const loginInitialValues = { emailOrPhone: "", password: "" };
export const loginSchema = Yup.object({
  emailOrPhone: emailOrPhoneSchema.required(
    "Email or phone number is required"
  ),
  password: Yup.string()
    .min(5, "password should at least 5 character")
    .required("please enter the password"),
});

// sign up schema
export const SignUpInitialValues = {
  name: "",
  type: "",
  emailOrPhone: "",
  mobile: "",
  password: "",
  confirmPassword: "",
};
export const signUpSchema = Yup.object({
  name: Yup.string().min(2).required("Enter your Company or Consultancy name"),
  type: Yup.string().required("Are you holding a company/consultancy?....."),
  mobile: Yup.number().min(10).required("mobile number is required"),
  emailOrPhone: emailOrPhoneSchema.required("Email-Id is required"),
  password: Yup.string()
    .min(5, "Please make the password more strong")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "Password should be match"),
});

// OTP schema
export const otpSchema = Yup.object({
  otp: Yup.number().min(2).required("Enter the valid otp"),
});

export const isPhoneNumber = (value) => {
  return phoneRegex.test(value);
};

// Forgot password
export const forgotPasswordInitialValues = {
  1: { emailOrPhone: "" },
  2: { otp: 0 },
  3: {
    password: "",
    confirmPassword: "",
  },
};

export const forgotPasswordSchema = {
  1: Yup.object({
    emailOrPhone: emailOrPhoneSchema.required(
      "email or phone number is required"
    ),
  }),
  2: Yup.object({
    otp: Yup.number().min(2).required("valid otp is required"),
  }),
  3: Yup.object({
    password: Yup.string()
      .min(5, "make it stronger password")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "both password should be match"),
  }),
};

export const candidateInterviewSchema = {
  interviewDate: "",
  startTime: "",
  endTime: "",
  interviewMode: "",
  meetingLink: "",
  status: "",
};

export const candidateInterviewValidation = Yup.object({
  interviewDate: Yup.string().required("Please select Interview Date."),
  startTime: Yup.string().required("Please select Interview Start Time."),
  endTime: Yup.string().required("Please select Interview End Time."),
  interviewMode: Yup.string().required("Please select Interview mode."),
  meetingLink: Yup.string().required("Please paste meeting link url....."),
  status: Yup.string().required("Please select interview status....."),
});

export const feedbackSchema = {
  feedback: "",
  status: "",
};

export const feedbackValidation = Yup.object({
  feedback: Yup.string().required("Please give me feedback details...."),
  status: Yup.string().required("Please select...."),
});

export const subscriptionPlanSchema = {
  duration: "",
  //paymentFor : '',
  //title: '',
  email: "",
  amount: "",
};

export const subscriptionValidation = Yup.object({
  duration: Yup.string().required("Please choose duration."),
  //paymentFor: Yup.string().required('Please select paymentFor.'),
  //title: Yup.string().required('Please input company/consultency name.'),
  email: Yup.string().required("Please input email-id."),
  amount: Yup.number().required("Amount is required"),
});

export const candidateProfileChecklistSchema = {
  lookingjob: "",
  noticeperiod: "",
  offer: "",
  lwd: "",
  pancardno: "",
};

export const candidateProfileValidation = Yup.object({
  lookingjob: Yup.string(),
  noticeperiod: Yup.string(),
  offer: Yup.string(),
  lwd: Yup.string().required("Please input lwd."),
  pancardno: Yup.string().required("Please input Pancard no."),
});

export const createClassInitialValues = {
  name: "",
  emailOrPhone: "",
  mobile: "",
  password: "",
  description: "",
  image: null,
};

export const createClassSchema = Yup.object({
  name: Yup.string()
    .min(2, "company name at least 2 character")
    .required("company name is required"),
  emailOrPhone: Yup.string().required("is required"),
  mobile: Yup.number().min(10).required("is required"),
  password: Yup.string()
    .min(5, "make it stronger password")
    .required("Password is required"),
  description: Yup.string(),
  image: Yup.mixed().nullable(),
});

export const assignmentInitialValue = {
  company_name: "",
  emailid: "",
  mobile: "",
};

export const assignmentSchema = Yup.object({
  company_name: Yup.string().required("is required"),
  emailid: Yup.string().required("is required"),
  mobile: Yup.number().min(10).required("is required"),
  image: Yup.mixed().nullable(),
});

export const profileCheckSchema = Yup.object({
  title: Yup.string()
    .min(5, "at least five character should be there")
    .required("is required"),
  total_IT_experience: Yup.string()
    .min(
      1,
      "please input any word or number format your total IT experience here...."
    )
    .required("is required"),
});

export const descriptionSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
});

export const announcementInitialValue = {
  title: "",
  description: "",
  exp_looking: "",
  desigination: "",
  job_mode: "",
  icon: "",
  announceAt: (() => {
    const currentDate = new Date();
    const isoString = currentDate.toISOString();
    const formattedDate = isoString.split("T")[0];
    return formattedDate;
  })(),
  theme: "black",
  action: "to-works",
};

export const announcementSchema = Yup.object({
  title: Yup.string().required(),
  description: Yup.string().required(),
  announceAt: Yup.date().required(),
  exp_looking: Yup.string().required(),
  desigination: Yup.string().required(),
  job_mode: Yup.string().required(),
  icon: Yup.string().required(),
  theme: Yup.string().required(),
  action: Yup.string(),
});
