import { useEffect, useState } from 'react';
import background from '../../assets/images/maintenance.gif'
import { useRouteError, useNavigate ,useLocation  } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authServices from '../../services/authService';
import { BASE_URL } from '../../constant/constant';
import { setNotification } from '../../utils/store/uiSlice';
import axios from 'axios';
import html2canvas from 'html2canvas';

const ErrorElement = ({ error }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [me, setMe] = useState()

  const routeError = useRouteError()
  const location = useLocation(); // Get the current route path
 

  useEffect(()=>{
    authServices.getMe().then(res=>{     
      setMe(res?.success?.user)
    })
  }, [])

  const handleContact = async () => {
   
      const imageCapture = document.body;
      
      const canvas = await html2canvas(imageCapture); // Capture the screenshot of the entire body
      const dataUrl = canvas.toDataURL('image/png');      
      const blob = await (await fetch(dataUrl)).blob();
      const file = new File([blob], 'screenshot.png', { type: 'image/png' });
      const formData = new FormData();
      formData.append('sender', me?.emailOrPhone);
      formData.append('message', 'Hello, I am OSSTechHire Customer, I want to inform the OSS Tech Team about this error. Please find the attachment.');
      formData.append('file', file); // Attach the screenshot

      // Log FormData contents
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }     
      try{
      const { data } = await axios.post(`${BASE_URL}/message/error/send-mail`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });     
      if(data?.success?.status === true){ 
        dispatch(setNotification({ success: data?.success?.status, message: 'Mail sent successfully! Our Technical Team will be resolve this issues asap.' }));
        navigate('/home');
    }
    } catch (error) {
      dispatch(setNotification({ success: false, message: error }))
    }   
    
  };

  return(
    <div className="flex flex-col items-center justify-center h-screen">
      <img draggable='false' src={background} alt="error" className='max-w-[250px]'/>
      <p className='font-bold  text-red-500'>{error.toString()}</p>
      <p className='max-w-md text-center'>If you encounter this error, please help us resolve the issue by sending an email
            with a screenshot </p> 
            <p>
				for more info you can join our largest community:{' '}
				<span className='text-primary'><i className="fa-solid fa-comment-dots"></i> <a href="https://osschat.in" _target="_blank">https://osschat.in</a></span> <br />
				or you can email at :{' '}<i className="fa-solid fa-envelope"></i> <span className='text-primary'>solution@osstechhire.com</span>
			</p>
      <h1>An error occurred!</h1>
      <p>Current Path: {location.pathname}</p> {/* Render the current path here */}
      <button
        onClick={handleContact}
        className='bg-primary text-white px-5 py-2 rounded-full mt-5 uppercase'>Email Me</button>    

    </div>
  )
};

export default ErrorElement;