import { createSlice } from "@reduxjs/toolkit";

const classesSlice = createSlice({
  name: "classes",
  initialState: {
    classes: null,
    currentClass: null,
    assignments: null,
  },
  reducers: {
    storeClasses: (state, action) => {
      state.classes = action.payload;
    },
    addClass: (state, action) => {
      state.classes = action.payload;
    },
    setCurrentClass: (state, action) => {
      state.currentClass = action.payload;
    },
    setAssignment: (state, action) => {
      state.assignments = action.payload;
    },
    classessClear: (state) => {
      state.classes = null;
      state.currentClass = null;
      state.assignments = null;
    },
  },
});

export const {
  storeClasses,
  addClass,
  setCurrentClass,
  setAssignment,
  classessClear,
} = classesSlice.actions;
export default classesSlice.reducer;
