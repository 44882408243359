export const BASE_URL = "https://onlinesoftservices.com:8700/api"; //'https://virtualclassroomserver.online/api';

export const BASIC_FEATURES = [
  { class: "fa-solid fa-people-roof", name: "Register as a Consultancy" },
  { class: "fa-solid fa-people-roof", name: "Register as a Company" },
  { class: "fa-solid fa-tv", name: "CVs Scanning via AI" },
  { class: "fa-solid fa-tv", name: "Generate Online Cvs" },
  { class: "fa-solid fa-clipboard-list", name: "Candidate On Call Check List" },
  {
    class: "fa-solid fa-tv",
    name: "Interview can schedule with Microsoft Team Meating, GoogleMeet, Zoom or other 3rd party platform if you want to intigrate can do without coding",
  },
  // { class: "fa-solid fa-message", name: "Discussions" },
  // { class: "fa-solid fa-photo-film", name: "materials sharing" },
  // { class: "fa-solid fa-clipboard-list", name: "assignments and exams" },
  // { class: "fa-solid fa-table-list", name: "Attendance tracking" },
];
export const PREMIUM_FEATURES = [
  {
    class: "",
    name: "For 1 Month Subscriptions All Above Feature's Included",
  },
  {
    class: "",
    name: "For 3 Month Subscriptions All Above Feature's Included",
  },
  {
    class: "",
    name: "For 6 Month Subscriptions All Above Feature's Included",
  },
  {
    class: "",
    name: "For 12 Month Subscriptions All Above Feature's Included",
  },
  // { class: "fa-solid fa-chart-line", name: "Track Progress" },
  // { class: "fa-solid fa-message", name: "Private and group chat" },
  // { class: "fa-solid fa-bell", name: "Notifications" },
  // { class: "fa-solid fa-square-poll-vertical", name: "Analytics and report" },
];

export const IP = "https://osstechhire.com:3005"; //'https://virtual-classroom-nine.vercel.app';

export const IMAGE_PATH = "https://onlinesoftservices.com:8700/images";

export const CHAT_SOCKET_IP = "https://osstechhire.com:3005"; //'https://virtualclassroomserver.online/';
export const MEETUP_SOCKET_IP = "https://osstechhire.com:9090";

export const ZEGO_SERVER_SECRET = "a156f502c0a69d7c56eb74c72d862014";
export const zegoAppId = 160329471;
