import { useFormik } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FormInput from "../common/FormInput";
import { loginSchema } from "../../schema/schema";
import { useEffect, useState } from "react";
import authServices from "../../services/authService";
import { setLocalStorage } from "../../utils/storageHelper";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../utils/store/userSlice";
import Button from "../common/Button";
import { setNotification } from "../../utils/store/uiSlice";
import SubscriptionPlan from "../../components/classroom/common/SubscriptionPlan";

const initialValues = { emailOrPhone: "", password: "" };

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popIsVisible, setPopIsVisible] = useState(false);
  const [message, setMessage] = useState();
  const storeClass = useSelector((store) => store.classes);

  const [signupURL, setSignupURL] = useState();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values) => {
        setLoading(true);

        const res = await authServices.login(values);
        setLoading(false);

        // Handle various error and success responses
        if (res?.error == 1) {
          // Email is invalid in both databases
          if (res?.db === "primary") {
            setMessage(
              "Your emailId is invalid! Please Signup for this emailId."
            );
            // Redirect to Primary DB Signup URL
            setSignupURL("/auth/signup"); // Update this URL as per your routing logic
          } else if (res?.db === "secondary") {
            setMessage(
              "Your emailId is invalid! Please Signup for this emailId."
            );
            // Redirect to Secondary DB Signup URL
            setSignupURL("https://register.onlinesoftservices.com"); // Update this URL as per your routing logic
          }
        }

        if (res?.error == 2) {
          setMessage("Your password is wrong! Please try with forgot password");
        }

        if (res?.error == 4) {
          setMessage("Your password is wrong! Please try with forgot password");
        }

        if (res?.error == 5) {
          setMessage(
            "Your account is not verified yet. please check your email inbox"
          );
        }

        if (res?.error == 6) {
          setLocalStorage("authentication", res?.authentication);
          setMessage(
            "Your Trial Period has been expired with OSSTechHire. Please buy a plan to continue using our services"
          );
          setPopIsVisible(true);
        }

        if (res?.success?.upload == "upload") {
          dispatch(
            setNotification({ success: true, message: "Login success" })
          );
          setLocalStorage("authentication", res?.success?.authentication);
          setLocalStorage("user", res?.success?.user);
          dispatch(userLogin(res?.success?.user));
          window.location.href = "https://osstechhire.com/cv";
        }

        if (res?.success) {
          dispatch(
            setNotification({ success: true, message: "Login success" })
          );
          setLocalStorage("authentication", res?.success?.authentication);
          const redirectPath = location.state?.from || "/";
          const showPopup = location.state?.showPopup || false;
          navigate(redirectPath, { state: { showPopup: true } });
          dispatch(userLogin(res?.success?.user));
        }
      },
    });

  useEffect(() => {
    let message =
      (touched.emailOrPhone && errors.emailOrPhone) ||
      (touched.password && errors.password);
    message && dispatch(setNotification({ success: false, message }));
  }, [dispatch, errors, touched]);

  return (
    <>
      <div className="relative z-[1] h-full md:grid grid-cols-2 gap-10">
        <div className="w-full h-full flex flex-col justify-center items-center">
          <h3 className="font-bold text-[3rem] text-center text-textColor">
            Hello!
          </h3>
          <p className="text-center text-textColor mb-3">
            Sign into your account
          </p>

          <form
            className="flex flex-col w-full max-w-md"
            onSubmit={handleSubmit}
          >
            <FormInput
              values={values.emailOrPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Email or Phone"
              id="emailOrPhone"
              type="email"
              name="emailOrPhone"
              placeholder="enter email"
              icon={<i className="ri-mail-settings-line font-bold text-xl"></i>}
            />
            <FormInput
              values={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Password"
              id="password"
              type="password"
              name="password"
              placeholder="enter the password"
              icon={<i className="ri-key-line font-bold text-xl"></i>}
            />
            <Button
              type="submit"
              loading={loading}
              className="mt-2 btn bg-primary text-white overflow-hidden rounded text-white font-bold text-center shadow-sm shadow-shadow uppercase"
            >
              LOGIN
            </Button>
            {message && (
              <h7>
                <span style={{ color: "red" }}>{message}</span>
              </h7>
            )}
          </form>

          <div className=" flex justify-between mt-3 w-full max-w-md flex-col sm:flex-row">
            <Link to={signupURL}>
              Don't have an account yet? <u>Sign up now!</u>
            </Link>

            <Link to="/auth/forgot-password">
              <u>Forgot password</u>
            </Link>
          </div>
        </div>

        <div className="hidden md:flex flex-col items-center justify-center">
          <p className="font-bold text-2xl mb-4 xl:text-[3rem]">Welcome back</p>
          <p className="text-center">
            We've missed you! Please sign in to catch up on what's new
          </p>
        </div>
      </div>
      <SubscriptionPlan
        visible={popIsVisible}
        setPopIsVisible={setPopIsVisible}
        setVisible={setPopIsVisible}
      />
    </>
  );
};

export default Login;
