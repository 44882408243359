import { useState, useEffect } from "react";
import Header from "../components/classroom/header/Header";
import Section from "../components/layouts/Section";
import Footer from "../components/layouts/Footer";
import image from "../assets/images/photo-young-lovely-girl-manager-600nw-2116114139.webp";
import image2 from "../assets/images/high-school-principal-using-digital-260nw-2339228201.webp";
import image3 from "../assets/images/hand-drawn-student-discount-sale-banner_23-2150639191.avif";
import {
  BASE_URL,
  BASIC_FEATURES,
  PREMIUM_FEATURES,
} from "../constant/constant";
import contactImage from "../assets/images/flat-customer-service-week-horizontal-banner-template_23-2149645764.avif";
// import image3 from '../assets/images/analytics-report.png'
// import image4 from '../assets/images/chat.png'
// import image5 from '../assets/images/exams.png'
// import image6 from '../assets/images/notifications.png'
// import image7 from '../assets/images/progress.png'
// import image8 from '../assets/images/video-conference.png'
import { decodeUser } from "../utils/storageHelper";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../components/common/Notification";
import SubscriptionPlan from "../components/classroom/common/SubscriptionPlan";
import Subscription from "./Subscription";
import "./homeAnimation";
import { useDispatch } from "react-redux";
import { setNotification } from "../utils/store/uiSlice";
import axios from "axios";

const Cancellation = () => {
  const user = decodeUser();

  const [plan, setPlan] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [popIsVisible, setPopIsVisible] = useState(false);
  //const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const showPopup = location.state?.showPopup;
    if (showPopup === true) {
      setPopIsVisible(true);
    }
  }, []);

  const popHandle = () => {
    if (user) {
      setPopIsVisible(true);
    } else {
      navigate("/auth/login", { state: { from: location.pathname } });
    }
  };

  const clickHandle = (plan) => {
    setPlan(plan);
  };

  const handleContact = async (e) => {
    e.preventDefault();
    let error;
    if (!name.trim()) {
      error = "name is required";
    } else if (!email.trim()) {
      error = "email is required";
    } else if (!message.trim()) {
      error = "message is required";
    }
    if (!error) {
      try {
        const { data } = await axios.post(`${BASE_URL}/message/send-mail`, {
          name: name.trim(),
          sender: email.trim(),
          message: message.trim(),
        });
        if (data?.success?.isSended) {
          setName("");
          setEmail("");
          setMessage("");
        }
        dispatch(
          setNotification({
            success: data?.success?.isSended,
            message: data?.success?.isSended
              ? "mail is sended"
              : "failed to send mail",
          })
        );
      } catch (error) {}
    } else {
      dispatch(setNotification({ success: false, message: error }));
    }
  };

  return (
    <>
      <div className="body">
        <Notification />
        <SubscriptionPlan
          visible={popIsVisible}
          setPopIsVisible={setPopIsVisible}
          setVisible={setPopIsVisible}
        />

        <Header page="home" />

        <Section>
          <div>
            <p>
              <h4>
                <u>
                  <b>Cancellation and Refund:</b>
                </u>
              </h4>
              <hr className="mt-0 mb-4" />
              <h6>Effective as of November 7, 2024 </h6>
            </p>{" "}
            <br />
            <p>
              These Cancellation and Refund govern your use of OSSTechHire.com.
              By using the platform, you agree to these points. If you do not
              agree, please refrain from using the platform.
            </p>
            <p>
              <h4>
                <u>
                  <b>1. Non-Refundable Consultancy Fees:</b>
                </u>
              </h4>
              <p>
                All consultancy fees, amounting to ₹2000 per month, are strictly
                non-refundable. Once paid, the consultancy fee is considered
                earned in full, regardless of whether the services have been
                utilized in part or full during the month.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>2. Non-Refundable Direct Client Fees:</b>
                </u>
              </h4>
              <p>
                The ₹500 monthly fee for direct client services is
                non-refundable under any circumstances. This fee is charged for
                access to specific services and resources that are made
                available to the client immediately upon payment.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>3. No Refund on Partial or Unused Services:</b>
                </u>
              </h4>
              <p>
                In cases where the client has used partial services or has not
                utilized the full offering, no refund will be issued for any
                unused portion of the consultancy or direct client fees. Clients
                are encouraged to fully utilize the services provided within the
                paid period.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>4. No Refund for Service Cancellation:</b>
                </u>
              </h4>
              <p>
                If the client wishes to cancel the services before the end of
                the billing cycle, no refund will be issued for the remaining
                balance of the service fees. Payments made are considered for
                the entire monthly cycle.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>5. Exceptions for Legal or Contractual Disputes:</b>
                </u>
              </h4>
              <p>
                Refunds or cancellations may only be considered in the event of
                a legal dispute or violation of the terms of agreement, and in
                such cases, refunds will be at the sole discretion of the
                company, following legal review and compliance with applicable
                laws.
              </p>
              <hr className="mt-0 mb-4" />

              <p>
                These points clearly define that the fees for both consultancy
                and direct client services are non-refundable under any
                conditions, helping protect your business revenue while
                maintaining clarity with your clients. Please feel free to reach
                out if you need any clarifications with us at
                terms@osstechhire.com
              </p>
            </p>
          </div>
        </Section>

        <Section>
          <Footer />
        </Section>
      </div>
    </>
  );
};

export default Cancellation;
