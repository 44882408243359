import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Button from "../../common/Button";
import screeningServices from "../../../services/screeningServices";
import lectureServices from "../../../services/lectureServices";
import { setNotification } from "../../../utils/store/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import JobDetails from "../../../components/classroom/common/JobDetails";

const AnnouncementCard = (props) => {
  const {
    profileId,
    assigned,
    count_job = 0,
    date,
    company_name = "Company Name",
    title = "Title",
    description = "The description goes here",
    buttonName,
    action,
    icon = "ri-play-circle-line",
    theme,
    id,
    handleClick,
    status: initialStatus,
    screeningCvs,
  } = props;

  const { classId, assignmentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((store) => store?.user);

  const [jobpopup, setJobpopup] = useState(false);

  const [color, setColor] = useState();

  const [isInScreening, setIsInScreening] = useState(false);

  useEffect(() => {
    let c = "gray";
    switch (theme) {
      case "black":
        c = "gray";
        break;
      case "danger":
        c = "red";
        break;
      case "success":
        c = "green";
        break;
      case "warning":
        c = "orange";
        break;
      case "hope":
        c = "indigo";
        break;
      default:
        c = "gray";
    }
    setColor(c);
  }, [theme]);

  if (!color)
    return (
      <div className="p-3 px-4 h-[100px] shimmer min-w-[200px] md:min-w-[250px] rounded-md max-w-[400px] shadow shadow-shadow"></div>
    );

  const isMatched = screeningCvs?.some((screening) =>
    screening.screening?.some((item) => item.announcementId === id)
  );

  const isProfileAssigned = assigned.includes(profileId);

  // Determine button text and disabled status based on conditions
  let buttonText;
  let isDisabled;
  let display;

  if (isProfileAssigned && isMatched) {
    buttonText = "Already Assigned";
    isDisabled = true;
  } else if (isProfileAssigned && !isMatched) {
    buttonText = "Already Assigned";
    isDisabled = true;
    display = "none";
  } else {
    buttonText = "Assign Me";
    isDisabled = false; // Default to enabled

    if (isMatched) {
      // No change in text or disabled status
    }
  }

  const JobDetailsPopupOpen = () => {
    setJobpopup(true);
  };

  const handleScreening = () => {
    navigate("/company/" + `${classId}` + "/dashboard/candidatecvs");
  };

  return (
    <>
      <JobDetails
        title={title}
        visible={jobpopup}
        setJobpopup={setJobpopup}
        announcementId={id}
        data={description}
        setVisible={setJobpopup}
      />
      <div className="p-3 px-4 justify-between min-w-full md:min-w-[350px] rounded-md flex md:gap-3 max-w-[400px] shadow transitions shadow-shadow hover:max-w-full">
        <div>
          <h4 className="text-sm md:text-2xl text-ellipsis max-w-[250px] max-h-[2rem] overflow-hidden">
            {`Name: `}
            {company_name}
          </h4>
          <h2 className="md:max-w-[450px] max-h-[50px] max-w-[220px] overflow-hidden ellipsis text-[16px] md:text-normal">
            {`Designation: `}
            {title}
          </h2>
          <div className="md:max-w-[450px] max-h-[50px] max-w-[220px] overflow-hidden ellipsis text-[12px] md:text-normal">
            {`Announce Date: `} {date}
          </div>
          <Link to={`/company/${classId}/dashboard/${id}/assigncandidatecvs`}>
            <p
              style={{
                padding: 0,
                color: "#007bff",
                marginTop: "-1px",
                marginRight: "80px",
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
              className="font-bold text-sm md:text-1xl text-ellipsis min-w-[150px] max-w-[1.5rem]"
            >
              {`Total Candidate Profile's Assigned: `}
              {count_job}
            </p>
          </Link>

          <div className="md:max-w-[450px] max-h-[50px] max-w-[220px] overflow-hidden ellipsis text-[12px] md:text-normal">
            {`Job Description: `}{" "}
            {isMatched ? (
              <a
                href="#"
                style={{
                  padding: 0,
                  color: "#007bff",
                  marginTop: "-1px",
                  marginRight: "80px",
                  textDecoration: "underline",
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                }}
                className="btn px-2 py-1 rounded-md bg-primary text-white mt-1 float-right mr-1"
                onClick={JobDetailsPopupOpen} // Open the job details popup
              >
                GET Details
              </a>
            ) : (
              <button
                onClick={JobDetailsPopupOpen} // Prevent click if disabled
                className={`overflow-hidden whitespace-nowrap text-white px-3 py-1 mt-2 min-w-[170px] max-w-[5rem] rounded font-normal text-center bg-blue-500`}
              >
                Go for Screening/ShortList
              </button>
            )}
          </div>
          {isMatched ? (
            <div>
              {!profileId ? null : (
                <div>
                  <button
                    style={{ display: `${display}` }}
                    onClick={() => !isDisabled && handleClick(id)} // Prevent click if disabled
                    disabled={isDisabled} // Disable button based on conditions
                    className={`overflow-hidden whitespace-nowrap text-white px-3 py-1 mt-2 min-w-[170px] max-w-[5rem] rounded uppercase font-normal text-center ${
                      isDisabled
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-500"
                    }`}
                  >
                    {buttonText}
                  </button>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default AnnouncementCard;
