import { useState, useEffect } from "react";
import { Form, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../../utils/formHelper";
//import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import TextField from "@mui/material/TextField";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ErrorMessage from "../../common/ErrorMessage";
import JoinClassInput from "../../common/JoinClassInput";
import classServices from "../../../services/classServices";
import subscriptionService from "../../../services/subscriptionService";
import { addClass } from "../../../utils/store/classesSlice";
import CreateClassInput from "../../common/CreateClassInput";
import {
  subscriptionPlanSchema,
  subscriptionValidation,
} from "../../../schema/schema";
import Button from "../../common/Button";
import { setNotification } from "../../../utils/store/uiSlice";
import { userLogOut } from "../../../utils/store/userSlice";
import axios from "axios";
import { BASE_URL } from "../../../constant/constant";
import logo from "../../../assets/images/logo-dark.png";

const SubscriptionPlan = ({ visible, setVisible, setPopIsVisible }) => {
  const [formData, setFormData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isJoin, setIsJoin] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const { profilecheckId } = useParams();
  const navigate = useNavigate();

  const classId = useSelector(
    (store) => store.classes?.currentClass?.class?._id
  );

  const popHandleClose = () => {
    setPopIsVisible(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(userLogOut());
    setTimeout(() => {
      localStorage.clear();
      navigate("/auth/login");
    }, 1000);
    setVisible(false);
  };

  const { formik, isSubmitting, handleChange } = useForm(
    subscriptionPlanSchema,
    subscriptionValidation,
    onSubmit
  );
  async function onSubmit(values) {
    const coursesdata = {
      email: values.email,
      subscribe: {
        type: values?.type,
        amount: values?.amount,
        duration: values?.duration,
      },
    };
    setFormData(coursesdata);
  }

  useEffect(() => {
    if (formData !== null) {
      displayRazorpay();
    }
  }, [formData]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorpay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = subscriptionService.buySubscription(formData).then((res) => {
      if (res) {
        const { amount, id: order_id, currency } = res;
        const options = {
          key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
          amount: amount,
          currency: currency,
          name: "OSSTechHire",
          description: "OSSTechHire",
          image: { logo },
          order_id: order_id,
          handler: async function (response) {
            const data = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            if (data) {
              const paymentData = {
                razorpayOrderId: data.razorpayOrderId,
                razorpayPaymentId: data.razorpayPaymentId,
                razorpaySignature: data.razorpaySignature,
              };
              Object.assign(formData.subscribe, paymentData);
              const gateway = { ...formData };
              const resultData = subscriptionService
                .createSubscriptionRazorPay(gateway)
                .then((res) => {
                  if (res.msg == "success") {
                    navigate("/");
                  }
                });
            } else {
              alert("Payment is Fail, please try after some times");
            }
          },
          prefill: {
            name: "OSSTechHire",
            email: "noreply@osstechhire.com",
            contact: "8826844273",
          },
          notes: {
            address: "OSSTechHire",
          },
          theme: {
            color: "#61dafb",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    });
  };

  const initialPlanValue =
    parseFloat(process.env.REACT_APP_INITIAL_PLAN_AMOUNT) || 0; // Base price for 1-month subscription
  const GST = 0.18; // 18% GST
  const discount = parseFloat(process.env.REACT_APP_PLAN_DISCOUNT_AMOUNT) || 0; // Use discount from .env, fallback to 0 if undefined or NaN

  useEffect(() => {
    const calculateAmount = (duration) => {
      let amount = 0; // Ensure default amount is 0 when no duration is selected

      if (duration === "30") {
        amount = initialPlanValue; // No discount for 1 month
      } else if (duration === "90") {
        amount = initialPlanValue * 3; // For 3 months
      } else if (duration === "180") {
        amount = initialPlanValue * 6; // For 6 months
      } else if (duration === "365") {
        amount = initialPlanValue * 12; // For 12 months
      }

      const gstAmount = amount * GST; // GST = basePrice * 18%
      let totalWithGst = amount + gstAmount; // Total = basePrice + GST

      // Apply discount for plans other than the 1-month plan
      if (duration !== "30" && amount !== 0) {
        totalWithGst -= discount; // Deduct discount from total
      }

      formik.values.amount = totalWithGst; // Store final amount in formik values
      return totalWithGst;
    };

    calculateAmount(formik?.values?.duration);
  }, [formik?.values?.duration]);

  return (
    <>
      {visible && (
        <div className="fixed top-0 left-0 w-full bg-black z-[1] bg-opacity-20 h-full flex items-center justify-center p-5 transition-colors">
          <div
            id="pop"
            className="bg-primary md:min-w-[500px] max-w-[500px] rounded-t-lg"
          >
            <div className=" flex w-full justify-end pe-3">
              <i
                className="fa-solid fa-xmark text-[2rem] text-white cursor-pointer my-2 hover:animate-spin"
                onClick={(e) => handleClose(e)}
              ></i>
            </div>
            <div className="bg-white rounded-t-lg p-5 transition">
              <ul className="flex gap-3 font-bold text-textColor">
                {/* <li
                  className={`cursor-pointer ${
                    isJoin ? 'border-b-[3px] border-primary' : ''
                  }`}
                  onClick={() => setIsJoin(true)}
                >
                  Join class
                </li> */}
                <li
                  className={`cursor-pointer ${
                    isJoin ? "" : "border-b-[3px] border-primary"
                  }`}
                  onClick={() => setIsJoin(true)}
                >
                  Subscription Details
                </li>
              </ul>

              <Form onSubmit={formik.handleSubmit} className="flex flex-col">
                <label htmlFor="type">
                  Subscription Type
                  <span className="text-red-500">{formik?.errors?.type}</span>
                </label>
                <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">
                  <select
                    name="type"
                    id="type"
                    value={formik?.values?.type}
                    onChange={formik?.handleChange}
                    className="w-full outline-none"
                  >
                    <option value="">Please Select</option>
                    <option value="FirstTime">First Time</option>
                    <option value="Extend">Extend</option>
                  </select>
                </div>
                <label htmlFor="duration">
                  Subscription Duration
                  <span className="text-red-500">
                    {formik?.errors?.duration}
                  </span>
                </label>
                <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">
                  <select
                    name="duration"
                    id="duration"
                    value={formik?.values?.duration}
                    onChange={formik?.handleChange}
                    className="w-full outline-none"
                  >
                    <option value="">Please Select</option>
                    <option value="30">1 Month</option>
                    <option value="90">3 Month</option>
                    <option value="180">6 Month</option>
                    <option value="365">12 Month</option>
                  </select>
                </div>
                {/* <label htmlFor="paymentFor">Are you Company/Consulstency<span className='text-red-500'>{formik?.errors?.paymentFor}</span></label>
                    <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">
                      <select
                          name="paymentFor"
                          id="paymentFor"
                          value={formik?.values?.paymentFor}
                          onChange={formik?.handleChange}                                                       
                          className="w-full outline-none"
                      >
                          <option value="">Please Select</option>
                          <option value="Company">Company</option>
                          <option value="Consultency">Consultency</option>                                                                       
                      </select>
                      </div> */}
                {/* <label htmlFor="title">Company/Consultency Name{formik?.errors?.title&&<span className='text-red-500'> {formik?.errors?.title}</span>}</label>
            <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">
              <input
                name="title"
                id="title"
                placeholder='Please input company/consultency name'
                value={formik?.values.title}
                onChange={formik?.handleChange}                
                className="w-full outline-none"
              />
            </div> */}
                <label htmlFor="email">
                  Email-Id
                  {formik?.errors?.email && (
                    <span className="text-red-500">
                      {" "}
                      {formik?.errors?.email}
                    </span>
                  )}
                </label>
                <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">
                  <input
                    name="email"
                    id="email"
                    placeholder="Please input email-id"
                    value={formik?.values.email}
                    onChange={formik?.handleChange}
                    className="w-full outline-none"
                  />
                </div>
                <label htmlFor="amount">
                  Plan Amount
                  {formik?.errors?.amount && (
                    <span className="text-red-500">
                      {" "}
                      {formik?.errors?.amount}
                    </span>
                  )}
                </label>
                <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">
                  <input
                    name="amount"
                    id="amount"
                    readOnly
                    value={formik?.values.amount}
                    onChange={formik?.handleChange}
                    className="w-full outline-none"
                  />
                </div>

                <Button
                  type="submit"
                  className="mt-2 btn overflow-hidden bg-primary hover:bg-indigo-600 px-1 py-1 rounded text-white font-bold text-center shadow-sm shadow-shadow "
                >
                  Buy Plan
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionPlan;
