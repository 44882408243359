import { useState, useEffect } from "react";
import Header from "../components/classroom/header/Header";
import Section from "../components/layouts/Section";
import Footer from "../components/layouts/Footer";
import image from "../assets/images/photo-young-lovely-girl-manager-600nw-2116114139.webp";
import image2 from "../assets/images/high-school-principal-using-digital-260nw-2339228201.webp";
import image3 from "../assets/images/hand-drawn-student-discount-sale-banner_23-2150639191.avif";
import {
  BASE_URL,
  BASIC_FEATURES,
  PREMIUM_FEATURES,
} from "../constant/constant";
import contactImage from "../assets/images/flat-customer-service-week-horizontal-banner-template_23-2149645764.avif";
// import image3 from '../assets/images/analytics-report.png'
// import image4 from '../assets/images/chat.png'
// import image5 from '../assets/images/exams.png'
// import image6 from '../assets/images/notifications.png'
// import image7 from '../assets/images/progress.png'
// import image8 from '../assets/images/video-conference.png'
import { decodeUser } from "../utils/storageHelper";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../components/common/Notification";
import SubscriptionPlan from "../components/classroom/common/SubscriptionPlan";
import Subscription from "./Subscription";
import "./homeAnimation";
import { useDispatch } from "react-redux";
import { setNotification } from "../utils/store/uiSlice";
import axios from "axios";

const Shipping = () => {
  const user = decodeUser();

  const [plan, setPlan] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [popIsVisible, setPopIsVisible] = useState(false);
  //const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const showPopup = location.state?.showPopup;
    if (showPopup === true) {
      setPopIsVisible(true);
    }
  }, []);

  const popHandle = () => {
    if (user) {
      setPopIsVisible(true);
    } else {
      navigate("/auth/login", { state: { from: location.pathname } });
    }
  };

  const clickHandle = (plan) => {
    setPlan(plan);
  };

  const handleContact = async (e) => {
    e.preventDefault();
    let error;
    if (!name.trim()) {
      error = "name is required";
    } else if (!email.trim()) {
      error = "email is required";
    } else if (!message.trim()) {
      error = "message is required";
    }
    if (!error) {
      try {
        const { data } = await axios.post(`${BASE_URL}/message/send-mail`, {
          name: name.trim(),
          sender: email.trim(),
          message: message.trim(),
        });
        if (data?.success?.isSended) {
          setName("");
          setEmail("");
          setMessage("");
        }
        dispatch(
          setNotification({
            success: data?.success?.isSended,
            message: data?.success?.isSended
              ? "mail is sended"
              : "failed to send mail",
          })
        );
      } catch (error) {}
    } else {
      dispatch(setNotification({ success: false, message: error }));
    }
  };

  return (
    <>
      <div className="body">
        <Notification />
        <SubscriptionPlan
          visible={popIsVisible}
          setPopIsVisible={setPopIsVisible}
          setVisible={setPopIsVisible}
        />

        <Header page="home" />

        <Section>
          <div>
            <p>
              <h4>
                <u>
                  <b>Shipping and Delivery:</b>
                </u>
              </h4>
              <hr className="mt-0 mb-4" />
              <h6>Effective as of November 7, 2024 </h6>
            </p>{" "}
            <br />
            <p>
              These Shipping and Delivery govern your use of OSSTechHire.com. By
              using the platform, you agree to these points. If you do not
              agree, please refrain from using the platform.
            </p>
            <p>
              <h4>
                <u>
                  <b>1. Digital Service Delivery Only:</b>
                </u>
              </h4>
              <p>
                All services provided through our platform are delivered
                digitally and do not involve physical shipping or delivery of
                goods. Upon successful payment, customers will gain instant or
                scheduled access to the services they have purchased through
                their online account.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>2. Instant Access to Online Services:</b>
                </u>
              </h4>
              <p>
                Once payment is processed, customers will receive immediate or
                scheduled access to their purchased services, which will be
                available through their user account on our platform. Access to
                services may be subject to account creation or activation, which
                will be guided through the platform interface.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>3. Platform Availability and Access:</b>
                </u>
              </h4>
              <p>
                All services are delivered exclusively online via our platform.
                Customers are responsible for ensuring they have the necessary
                technology, including internet access, to access and use the
                services. If any technical issues occur, customers should
                contact our support team for assistance, but refunds or credits
                will not be issued for these issues.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>4. No Physical Delivery:</b>
                </u>
              </h4>
              <p>
                As our services are purely digital, there is no physical
                delivery process involved. Customers will not receive any
                physical items or documents. Services are accessible online and
                can be utilized directly through the user dashboard or other
                designated areas of the platform.
              </p>
            </p>
            <p>
              <h4>
                <u>
                  <b>5. Service Availability and Timelines:</b>
                </u>
              </h4>
              <p>
                The delivery of services is based on platform access and
                availability. If there are any scheduled timelines for service
                delivery, such as consultations, appointments, or course access,
                these will be communicated in advance via email or through the
                platform's notification system. Delays in service delivery due
                to technical issues on the platform will be addressed promptly
                by our support team, but such delays do not constitute grounds
                for refunds.
              </p>
              <hr className="mt-0 mb-4" />

              <p>
                These points ensure customers are fully aware that the services
                they purchase will be delivered through an online platform, and
                outline the process and expectations regarding access to those
                services without physical shipping. Please feel free to reach
                out if you need any clarifications with us at
                terms@osstechhire.com
              </p>
            </p>
          </div>
        </Section>

        <Section>
          <Footer />
        </Section>
      </div>
    </>
  );
};

export default Shipping;
