import { useState, useEffect } from 'react';
import Header from '../components/classroom/header/Header';
import Section from '../components/layouts/Section';
import Footer from '../components/layouts/Footer';
import image from '../assets/images/photo-young-lovely-girl-manager-600nw-2116114139.webp';
import image2 from '../assets/images/high-school-principal-using-digital-260nw-2339228201.webp';
import image3 from '../assets/images/hand-drawn-student-discount-sale-banner_23-2150639191.avif';
import { BASE_URL, BASIC_FEATURES, PREMIUM_FEATURES } from '../constant/constant';
import contactImage from '../assets/images/flat-customer-service-week-horizontal-banner-template_23-2149645764.avif';
// import image3 from '../assets/images/analytics-report.png'
// import image4 from '../assets/images/chat.png'
// import image5 from '../assets/images/exams.png'
// import image6 from '../assets/images/notifications.png'
// import image7 from '../assets/images/progress.png'
// import image8 from '../assets/images/video-conference.png'
import { decodeUser } from "../utils/storageHelper";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from '../components/common/Notification';
import SubscriptionPlan from '../components/classroom/common/SubscriptionPlan';
import Subscription from './Subscription';
import './homeAnimation';
import { useDispatch } from 'react-redux';
import { setNotification } from '../utils/store/uiSlice';
import axios from 'axios';

const TermsOfConditions = () => {
  const user = decodeUser();

  const [plan, setPlan] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch()

  const [popIsVisible, setPopIsVisible] = useState(false);
  //const [showPopup, setShowPopup] = useState(false);

  useEffect(()=>{
    const showPopup = location.state?.showPopup;   
    if(showPopup === true){       
        setPopIsVisible(true);    
      }
  },[])
      

  const popHandle = () => {
    if(user){
      setPopIsVisible(true);      
    } else {
      navigate('/auth/login', { state: { from: location.pathname } });
    }
    
  };

  const clickHandle = (plan) => {
    setPlan(plan);
  };

  const handleContact = async (e) => {
    e.preventDefault();
    let error;
    if (!name.trim()) { error = 'name is required'}
    else if (!email.trim()) { error = 'email is required'}
    else if (!message.trim()) { error = 'message is required'}
    if (!error) {
      try {
        const {data} = await axios.post(`${BASE_URL}/message/send-mail`, {
          name: name.trim(), sender: email.trim(), message: message.trim()
        })
        if (data?.success?.isSended) {
          setName('')
          setEmail('')
          setMessage('')
        }
        dispatch(setNotification({ 
          success: data?.success?.isSended,
          message: data?.success?.isSended ? 'mail is sended' : 'failed to send mail'
        }))
      } catch (error) {
        
      }
    }
    else {
      dispatch(setNotification({ success: false, message: error }))
    }
  };

  return (
    <>
      <div className="body">
        <Notification />
        <SubscriptionPlan visible={popIsVisible} setPopIsVisible={setPopIsVisible} setVisible={setPopIsVisible} />
       
          <Header page="home" />
                
        <Section> 
          <div>
                <p>
                <h4><u><b>Terms and Conditions:</b></u></h4>
                <hr className="mt-0 mb-4" />  
                <h6>Effective as of October 7, 2024 </h6>
                </p>            
                <p>
                These Terms and Conditions govern your use of OSSTechHire.com. By using the platform, you agree to these terms. If you do not agree, please refrain from using the platform.
                </p>                
                <p>
                <h4><u><b>1. Service Overview:</b></u></h4> 
                    <p>
                    OSSTechHire.com provides IT consultancies and IT companies with a platform to access various services, including:
                    <ul>
                      <li>Subscription-based services (2000 Rs per month for consultancy firms).</li>
                      <li>Company registration services (500 Rs one-time fee for registration through a consultancy).</li>
                      <li>Data analysis services powered by ChatGPT and GeminiAPI.</li>
                      <li>Email services for communication.</li>
                    </ul>
                    </p>
                </p>

                <p>
                <h4><u><b>2. Subscription Plans:</b></u></h4>
                <p>
                Consultancies and companies are required to pay a subscription fee to use the platform. The subscription fee for consultancies is 2000 Rs per month, and for companies, a similar subscription plan applies. GST is applicable as per Indian Government policy.
                </p>
                </p>
                <h4><u><b>3. Payment Terms:</b></u></h4>
                <p>
                  <ul>
                    <li>All payments for subscriptions and services must be made via the payment methods available on the platform.</li>
                    <li>Consultancies are required to make a one-time payment of 500 Rs for company registrations.</li>
                    <li>GST charges are added as per applicable laws.</li>
                    <li>Failure to pay subscription fees on time may result in service interruption or termination.</li>                    
                  </ul>
                </p>
                
                <p>
                <h4><u><b>4. Usage of Paid Services:</b></u></h4>
                <hr className="mt-0 mb-4" />
                <p>
                Paid services on OSSTechHire.com include: <br /> 
                 <ul>
                  <li>ChatGPT and GeminiAPI for advanced data filtering and analysis.</li>
                  <li>Email services for consultancies and companies. Access to these services may incur additional charges.</li>
                 </ul>
                </p>

                <h4><u><b>5. Account Responsibility:</b></u></h4>
                <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. Any unauthorized use of your account must be reported immediately.</p>

                <h4><u><b>6. Refund Policy:</b></u></h4>
                <p>We do not offer refunds for any subscription or service fees once they have been paid. If there is a billing issue, please contact our support team for assistance.</p>

                <h4><u><b>7. Termination of Service:</b></u></h4>
                <p>
                OSSTechHire reserves the right to suspend or terminate any user account if there is a breach of these terms, including failure to pay subscription fees or misuse of the platform’s services.
                </p>

                <h4><u><b>8. Intellectual Property:</b></u></h4>
                <p>All content on OSSTechHire.com, including text, graphics, logos, and software, is the property of OSSTechHire and is protected by intellectual property laws. Users may not reproduce, distribute, or use any content without our express permission.</p>

                  <h4><u><b>9. Limitation of Liability:</b></u></h4>
                  <p>
                  OSSTechHire is not liable for any indirect, incidental, or consequential damages that may arise from the use or inability to use the platform, including the use of third-party services like ChatGPT and GeminiAPI.
                  </p>

                  <h4><u><b>10. Changes to Terms and Conditions:</b></u></h4>
                  <p>We may modify these terms at any time. Users will be notified of any changes, and continued use of the platform signifies acceptance of the updated terms.</p>

                  <hr className="mt-0 mb-4" />

                  <p>These policies are designed to protect both OSSTechHire.com and its users, ensuring a clear understanding of responsibilities and rights. Please feel free to reach out if you need any clarifications with us at terms@osstechhire.com</p>
                  </p>
                </div>
        </Section>

        <Section>
        <Footer />
        </Section>
      </div>
    </>
  );
};

export default TermsOfConditions;
