import axios from "axios";
import { BASE_URL } from "../constant/constant";

const ClassServices = {
  getAllClasses: async () => {
    try {
      const token = localStorage.getItem("authentication");
      axios.defaults.headers.common["Authorization"] = token;
      return axios.get(BASE_URL + "/classes").then((res) => {
        if (res.data.token === false) {
          localStorage.clear();
        }
        if (res?.data?.success) {
          return res?.data?.success;
        }
        return [];
      });
    } catch (error) {}
  },

  uploadCv: async (values) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    return axios
      .post(BASE_URL + "/upload", values, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => err);
  },

  createClass: async (values) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    return axios
      .post(BASE_URL + "/classes", values, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => err);
  },
  joinClass: async (uuid) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    return axios
      .post(BASE_URL + "/classes/join", { uuid })
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err));
  },
  getClass: async (classId) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    return axios.get(`${BASE_URL}/classes/${classId}`).then((res) => {
      return res.data;
    });
  },
  updateClass: async (classId, name, description, instructor = null) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    return axios
      .patch(BASE_URL + "/classes/" + classId, {
        name,
        description,
        instructor,
      })
      .then((res) => {
        return res.data;
      });
  },
  updateProfileCheck: async (profilecheckId, values) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    axios
      .post(
        BASE_URL +
          `/classes/${profilecheckId}/profilecheck/candidatechecklistupdate`,
        values
      )
      .then((res) => {
        return res.data;
      });
  },
  getClasses: async (classId) => {
    const token = localStorage.getItem("authentication");
    axios.defaults.headers.common["Authorization"] = token;
    return axios
      .get(`${BASE_URL}/classes/as-student`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => console.log(error));
  },
};

export default ClassServices;
