const CreateClassInput = ({name, type='text', onChange, value='',errorMessage}) => {
  return (
    <div className=" mt-5 gap-3 justify-center md:grid grid-cols-2">
      <label htmlFor={name} className="text-black text-[15px]">{name}</label>
      <div className="flex flex-col">
      <input 
          name={name}
          id={name}
          placeholder={`company ${name}`} 
          type={type} 
          className="bg-gray-200 rounded border-2 border-gray-300 px-2 outline-primary w-full py-1 focus:bg-blue-50"
          onChange={onChange} 
          value={value}
        />
        {errorMessage && (
          <span className="text-red-500 text-right">{errorMessage}</span>
        )}
      </div>
    </div>
  )
}

export default CreateClassInput;