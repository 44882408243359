import scanner from '../../assets/images/scanner.gif';


const ScannerImage = () => {    
  
  return (
    <>
      <img draggable='false' src={scanner} alt="user profile" className="max-w-[4rem] rounded-xl border-2 border-white"/>
      <span style={{color: 'red'}}>Please note this is AI based scanning you will be found most relevent or equal data according to your need</span>
    </>
  );
};

export default ScannerImage;
